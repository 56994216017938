import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EnvService } from '../../../env.service';
import { constants } from '../../../common/constants';

import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';

import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BillingHttpService {

  constructor(
    private httpClient: HttpClient,
    private env: EnvService
  ) { }

  getOrganisationLists() {
    const organisation = JSON.parse(localStorage.getItem(constants.STORAGE_KEYS.ORGANISATIONS));
    if (organisation !== undefined && organisation !== null) {
      return of(organisation);
    } else {
    const API_URL = `${this.env.apiUrl}/Organisations`;
    return this.httpClient.get(API_URL).map((data: any) => {
      localStorage.setItem(constants.STORAGE_KEYS.ORGANISATIONS, JSON.stringify(data));
      return data;
    }).catch((error: any) => {
      return Observable.throw(error);
    });
  }
  }

   /**
   * Called by the datatable whenever a page or page size changes
   * or a column is reoredered.
   * @param {any} params
   * @param {any} dtCallback
   */

  getBillingLists(params, pageNumber, dtCallback, orgId, searchTerm, sortingColumn, sortingOrder) {
    const API_URL = `${this.env.apiUrl}/Organisations/${orgId}/Invoices?PageNumber=${pageNumber}&PageLimit=${params.length}&SortingColumn=${sortingColumn}&SortingOrder=${sortingOrder}&SearchTerm=${searchTerm}&OrgId=${orgId}`;
    return this.httpClient.get(API_URL).map((data: any) => {
      dtCallback({
        recordsTotal: data.Data.TotalNumberOfRecords || 0,
        recordsFiltered: data.Data.TotalNumberOfRecords || 0,
        data: []
      });
      return data;
    }).catch((error: any) => {
      return Observable.throw(error);
    });
  }

  exportInvoices() {
    const API_URL = `${this.env.apiUrl}/Invoices?Export=true`;
    return this.httpClient.get(API_URL).map((data: any) => {
      return data;
    }).catch((error: any) => {
      return Observable.throw(error);
    });
  }

  downloadFile(filePath) {
    let a = document.createElement('a')
    a.href = filePath;
    a.download = filePath.split('/').pop()
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }
}

